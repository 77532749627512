
import { ref, defineComponent, reactive, toRefs, onMounted, watch } from 'vue';
import UploadDetail from './UploadDetail/index.vue';
import useManagement from '@/hooks/checking/useManagement';
import { useRoute } from 'vue-router';
import downloadFile from '@/utils/claim/downloadFile';
import fixTable from '@/hooks/checking/useFixTable';
import { message } from 'ant-design-vue';

export default defineComponent({
    components: {
        UploadDetail,
    },
    setup() {
        const route = useRoute();

        const tableWidth = reactive({
            'ag-management-c1': 1450,
            'ag-management-c2': 1190,
            'ta-management-c1': 1650,
            'ta-management-c2': 1390,
        });

        const {
            name,
            year,
            yearList,
            status,
            statusList,
            bu,
            buList,
            type,
            typeList,
            quarter,
            quarterList,
            searchParams,
            reset,
            columns,
            colType,
            getTableData,
            tableData,
            paginationObj,
            pageChange,
            sizeChange,
        } = useManagement();

        //* 限定表格
        const height: number = 100 + 50 + 150 + 10 + 15 + 32 + 10 + 48 + 10;
        const tableHeight = fixTable(height);

        const state = reactive<any>({
            selectedRowKeys: [], // Check here to configure the default column
            selectedRows: [],
        });
        const onSelectChange = (selectedRowKeys: [], selectedRows: []) => {
            state.selectedRowKeys = selectedRowKeys;
            state.selectedRows = selectedRows;
        };

        watch(tableData, () => {
            state.selectedRow = [];
            state.selectedRowKeys = [];
        });

        //? 上传功能逻辑
        const isUploadVisible = ref<boolean>(false);
        const openUploadDrawer = () => {
            isUploadVisible.value = true;
        };
        const closeUpload = (param: { isUploadVisible: boolean }) => {
            isUploadVisible.value = param.isUploadVisible;
            getTableData();
        };

        //? 导出功能
        const exportData = (): void => {
            if (state.selectedRowKeys.length === 0) {
                message.error('Please select one and click again!');
                return;
            }
            const selectedRow = state.selectedRows[0];
            if (route.name === 'ta-management') {
                const params = {
                    url: '/claimapi/claimChecking/taManagement/export',
                    method: 'post',
                    params: {
                        businessUnit: selectedRow.businessunit,
                        quarter: selectedRow.quarter,
                        type: selectedRow.type,
                        year: selectedRow.year,
                        size: 0,
                        current: 0,
                    },
                };
                downloadFile(params, 'application/vnd-excel; char-set=UTF-8');
            }
            if (route.name === 'ag-management') {
                const params = {
                    url: '/claimapi/claimChecking/agManagement/export',
                    method: 'post',
                    params: {
                        bu: selectedRow.bu,
                        year: selectedRow.year,
                    },
                };
                downloadFile(params, 'application/vnd-excel; char-set=UTF-8');
            }
        };

        return {
            tableWidth,
            //
            name,
            year,
            yearList,
            status,
            statusList,
            bu,
            buList,
            type,
            typeList,
            quarter,
            quarterList,
            //
            tableHeight,
            searchParams,
            reset,
            columns,
            colType,
            getTableData,
            tableData,
            onSelectChange,
            ...toRefs(state),
            //
            isUploadVisible,
            paginationObj,
            pageChange,
            sizeChange,
            openUploadDrawer,
            closeUpload,
            exportData,
        };
    },
});
