import usePage from '@/hooks/claim/usePage';
import range from '@/utils/checking/rangeArray';
import { ref, reactive, watch, onMounted } from 'vue';
import {
    AGManagementQueryParams,
    AGManagementTableData,
    TAManagementQueryParams,
    TAManagementTableData,
    agColumns1,
    agColumns2,
    taColumns1,
    taColumns2,
} from '@/views/Checking/CheckingManagement/type';
import {
    getAGManagementData,
    getTAManagementData,
} from '@/API/checking/checkingManagement';
import { useRoute } from 'vue-router';
import { getUser } from '@/API/checking/checkingManagement';

const STARTYEAR = 2020;
const myDate: Date = new Date();
const endYear: number = myDate.getFullYear() + 2;

const useManagement = () => {
    const route = useRoute();

    //* 共有的字段
    const name = ref<string | null>(null);
    const year = ref<string | null>(null);
    const yearList = ref<number[]>(range(STARTYEAR, endYear, 1));

    //* AG TA 共有字段
    const bu = ref<string | null>(null);
    const buList = ref<any[]>([]);

    //* AG Management 需要的字段
    const status = ref<string | null>(null);
    const statusList = ref<string[]>(['Active', 'Non Active']);

    //* TA Mangement 需要的字段
    const type = ref<string | null>(null);
    const typeList = ref<string[]>(['WS', 'RT', 'ATD']);
    const quarter = ref<string | null>(null);
    const quarterList = ref<string[]>(['Q1', 'Q2', 'Q3', 'Q4']);

    const { paginationObj, pageChange, sizeChange } = usePage();

    const columns = ref<{ title: string; dataIndex: string; width?: number }[]>(
        []
    );

    //* 表头
    const AGColumns1 = reactive(agColumns1);
    const AGColumns2 = reactive(agColumns2);
    const TAColumns1 = reactive(taColumns1);
    const TAColumns2 = reactive(taColumns2);

    if (route.name === 'ag-management') {
        columns.value = AGColumns1;
    } else if (route.name === 'ta-management') {
        columns.value = TAColumns1;
    }

    // watch(bu, (newVal) => {
    //     if (['VAN', 'DENZA'].includes(newVal as string)) {
    //         if (route.name === 'ag-management') {
    //             columns.value = AGColumns2;
    //         } else {
    //             columns.value = TAColumns2;
    //         }
    //     } else {
    //         if (route.name === 'ag-management') {
    //             columns.value = AGColumns1;
    //         } else {
    //             columns.value = TAColumns1;
    //         }
    //     }
    // });
    const colType = ref<string>('c1');
    const setColumns = () => {
        if (['VAN', 'DENZA'].includes(bu.value as string)) {
            if (route.name === 'ag-management') {
                columns.value = AGColumns2;
            } else {
                columns.value = TAColumns2;
            }
            colType.value = 'c2';
        } else {
            if (route.name === 'ag-management') {
                columns.value = AGColumns1;
            } else {
                columns.value = TAColumns1;
            }
            colType.value = 'c1';
        }
    };

    let searchParams = reactive<
        AGManagementQueryParams | TAManagementQueryParams
    >({});

    // const tableData = ref<TAManagementTableData[]>([]);
    const tableData = ref<AGManagementTableData[] | TAManagementTableData[]>(
        []
    );
    const reset = () => {
        name.value = null;
        status.value = null;
        type.value = null;
        bu.value = buList.value[0].nameEn;
        year.value = null;
        quarter.value = null;
        searchParams = reactive<
            AGManagementQueryParams | TAManagementQueryParams
        >({});
    };
    const getTableData = () => {
        searchParams.businessUnit = bu.value;
        searchParams.current = paginationObj.current - 1;
        searchParams.size = paginationObj.size;
        searchParams.name = name.value || '';
        searchParams.year = year.value || '';
        if (route.name === 'ag-management') {
            (searchParams as AGManagementQueryParams).status =
                status.value || '';
            getAGManagementData(searchParams as AGManagementQueryParams).then(
                (res) => {
                    tableData.value = res.agList;
                    paginationObj.total = res.total;
                }
            );
        }
        if (route.name === 'ta-management') {
            (searchParams as TAManagementQueryParams).quarter =
                quarter.value || '';
            (searchParams as TAManagementQueryParams).type = type.value || '';
            getTAManagementData(searchParams as TAManagementQueryParams).then(
                (res) => {
                    tableData.value = res.data;
                    paginationObj.total = res.totalNum;
                }
            );
        }
        setColumns();
    };

    watch(
        () => route.name,
        (newName, oldName) => {
            if (route.name === 'ag-management') {
                columns.value = AGColumns1;
            } else if (route.name === 'ta-management') {
                columns.value = TAColumns1;
            }
            if (newName !== oldName) {
                tableData.value = [];
                reset();
                getTableData();
            }
        }
    );

    onMounted(() => {
        // 过滤掉 fleet MBCPO AMGCPO CompanyCar
        const noUseBuIds = [6, 7, 8, 9];
        getUser().then((res) => {
            buList.value = res.buList
                .filter((ele: any) => {
                    return ele.parentId === null;
                })
                .filter((ele: any) => {
                    return !noUseBuIds.includes(ele.id);
                });
            bu.value = buList.value[0].nameEn;
            getTableData();
        });
    });

    return {
        name,
        year,
        yearList,
        status,
        statusList,
        bu,
        buList,
        type,
        typeList,
        quarter,
        quarterList,
        searchParams,
        reset,
        colType,
        columns,
        getTableData,
        tableData,
        // fakeDataList,
        paginationObj,
        pageChange,
        sizeChange,
    };
};

export default useManagement;

// const fakeDataList = ref<AGManagementTableData[]>([]);
// fakeDataList.value = genFakeData(20);
// const genFakeData = (num: number): AGManagementTableData[] => {
//     const fakeDataList: AGManagementTableData[] = [];
//     for (let i = 0; i < num; i++) {
//         const fakeDataObj: AGManagementTableData = {
//             id: i,
//             name: `name ${i}`,
//             year: '2020',
//             dealerCount: 123,
//             cbu: 123,
//             pbP: 123,
//             total: 369,
//             status: 'Active',
//             uploadBy: 'fake User',
//             uploadAt: '2021/5/1 16:00:11',
//         };
//         fakeDataList.push(fakeDataObj);
//     }
//     return fakeDataList;
// };
