import axios from 'axios';
import { AxiosRequestConfig } from '@/API/types';
export default (options: AxiosRequestConfig, type: string) => {
    const { url, method = 'get', params, fileName } = options;
    const config: any = {
        url: url,
        method,
        responseType: 'blob',
    };
    config['params'] = params;
    return new Promise((resolve, reject) => {
        axios(config)
            .then((res: any) => {
                // 文件格式
                console.log(res);
                const blob = new Blob([res.data], { type: type }); //指定格式为vnd.ms-excel
                const downloadElement = document.createElement('a');
                const href = window.URL.createObjectURL(blob); //创建下载的链接
                downloadElement.href = href;
                const str = res.headers['content-disposition'];
                const index = str.lastIndexOf('=');

                console.log(res.headers['content-disposition']);
                if (str.substring(index + 1, str.length)) {
                    downloadElement.download = decodeURI(
                        str.substring(index + 1, str.length)
                    );
                } else if (fileName) {
                    downloadElement.download = fileName;
                } else {
                    downloadElement.download = Date.now().toString();
                }
                document.body.appendChild(downloadElement);
                downloadElement.click(); //点击下载
                document.body.removeChild(downloadElement); //下载完成移除元素
                window.URL.revokeObjectURL(href); //释放掉blob对象
                resolve(res);
            })
            .catch(function(error: string) {
                console.log(error);
                return reject(error);
            });
    });
};
