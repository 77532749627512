
import axios from 'axios';
import { defineComponent, reactive, ref } from 'vue';
import {
    ExclamationCircleOutlined,
    CheckCircleOutlined,
} from '@ant-design/icons-vue';
// import { getUploadRoundList } from '@/API/claim/claimWS';
// import { PayoutRoundListData } from '@/views/Claim/WS/types';
import { FileItem } from '@/API/types';
import downloadFile from '@/utils/claim/downloadFile';
import { message } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import downLoadTemplate from '@/utils/checking/downloadFile';
import downloadFileUrl from "@/utils/claim/downloadFileUrl";

export default defineComponent({
    components: {
        ExclamationCircleOutlined,
        CheckCircleOutlined,
    },
    emits: ['close-upload-visible', 'change-table'],
    props: {
        isUploadVisible: {
            type: Boolean,
            required: true,
            default: false,
        },
        buList: {
            type: Array,
            default: () => [],
        },
    },
    setup(props, context) {
        const route = useRoute();
        //
        const bu = ref<string | null>(null);
        const year = ref<string | null>(null);
        const yearList = ref<number[]>([]);
        const getYearList = (): number[] => {
            const currentDate: Date = new Date();
            const currentYear = currentDate.getFullYear();
            const yearList: number[] = [
                currentYear - 1,
                currentYear,
                currentYear + 1,
            ];
            return yearList;
        };
        yearList.value = getYearList();
        const quarter = ref<string | null>(null);
        const quarterList = ref<string[]>(['Q1', 'Q2', 'Q3', 'Q4']);

        const isEmptyYear = ref<boolean>(false);
        const isEmptyBu = ref<boolean>(false);
        const isEmptyQuarter = ref<boolean>(false);

        //!
        // const isShowErrorTips = ref<boolean>(false);
        const isBeforeUpload = ref<boolean>(true);
        // 进度条
        const isShowUploadBtn = ref<boolean>(true);
        const curUploadFile = ref<object>({});
        const curPercent = ref<number>(0);
        const uploadFileSize = ref<string>('');
        const completeTips = ref<boolean>(false);
        // 上传功能出错后下载模态窗口
        const loading = ref<boolean>(false);
        const downloadModalVisible = ref<boolean>(false);
        const downloadTipsText = ref<string>('');
        const roundVal = ref();
        // 导入控制按钮
        const isImport = ref<boolean>(false);
        // 文件上传
        const isClickAbled = ref<boolean>(true);
        const uploadComplete = ref<boolean>(true);
        const formData = new FormData();
        const emptyVisible = ref<boolean>(false);
        const emptyTipsText = ref<string>('');
        // 获取标题内容
        // const title = ref<string>('Upload AG');
        const title = reactive({
            'ag-management': 'Upload AG',
            'ta-management': 'Upload Sign TA',
        });
        // const title = computed(() => `Upload ${(props as any).curRole} Reimbursement List`)
        // // 设置抽屉显示状态
        const modal = (props as any).isUploadVisible;
        // const roleVal = (props as any).curRole;
        const close = () => {
            context.emit('close-upload-visible', { isUploadVisible: false });
        };
        // const roundList = ref<PayoutRoundListData[]>([]);
        // 获取upload round 列表
        // const uploadListParams = { afcLcType: 'AFC', type: 'WS' };
        // const params = uploadListParams;
        // getUploadRoundList({ params }).then((res) => {
        //     roundList.value = res;
        // });
        // 上传前
        const beforeUpload = (file: FileItem) => {
            const isXlsx =
                file.type ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            if (!isXlsx) {
                message.error('Only xlsx format files can be uploaded!');
            }
            const isLt5M = file.size / 1024 / 1024 < 5;
            if (!isLt5M) {
                message.error('The file size should not be larger than 5MB!');
            }
            return isXlsx && isLt5M;
        };
        const uploadRequest = (source: any) => {
            if (formData.has('file')) {
                formData.delete('file');
            }
            formData.append('file', source.file);
            curUploadFile.value = source.file;
            uploadFileSize.value =
                source.file.size / 1024 > 1024
                    ? `${(source.file.size / 1024 / 1024).toFixed(1)} MB`
                    : `${Math.floor(source.file.size / 1024)} KB`;
            isBeforeUpload.value = false;
        };

        const queryCheck = (): boolean => {
            if (year.value === undefined) {
                isEmptyYear.value = true;
            } else if (year.value === null) {
                isEmptyYear.value = true;
            } else {
                isEmptyYear.value = false;
            }
            if (bu.value === undefined || bu.value === null) {
                isEmptyBu.value = true;
            } else if (
                bu.value === '' ||
                bu.value === undefined ||
                bu.value === null
            ) {
                isEmptyBu.value = true;
            } else {
                isEmptyBu.value = false;
            }
            if (route.name === 'ta-management') {
                if (quarter.value === undefined || quarter.value === null) {
                    isEmptyQuarter.value = true;
                } else if (quarter.value?.trim() === '') {
                    isEmptyQuarter.value = true;
                } else {
                    isEmptyQuarter.value = false;
                }
                if (
                    isEmptyYear.value ||
                    isEmptyBu.value ||
                    isEmptyQuarter.value
                ) {
                    return true;
                } else {
                    return false;
                }
            }
            if (isEmptyYear.value || isEmptyBu.value) {
                return true;
            } else {
                return false;
            }
        };

        // 上传文件
        const uploadFile = (source: any) => {
            if (queryCheck()) return;
            isClickAbled.value = false;

            let params = {};
            let url = '';
            if (route.name === 'ta-management') {
                params = {
                    year: year.value,
                    bu: bu.value,
                    quarter: quarter.value,
                    file: formData,
                };
                url = '/claimapi/claimChecking/taManagement/upload';
            }
            if (route.name === 'ag-management') {
                formData.set('bu', bu.value as string);
                formData.set('year', year.value as string);
                url = '/claimapi/claimChecking/agManagement/upload';
            }

            axios({
                method: 'post',
                data: formData,
                url,
                params: params,
            })
                .then((res) => {
                    isShowUploadBtn.value = false;
                    curPercent.value = 100;
                    completeTips.value = true;
                    isClickAbled.value = true;
                    if (res.data.code !== '0' && res.data.code !== '50056') {
                        emptyVisible.value = true;
                        emptyTipsText.value = res.data.message;
                    } else if (res.data.code === '50056') {
                        // 显示模态窗口
                        downloadModalVisible.value = true;
                        downloadTipsText.value = res.data.message;
                        // 存data里的信息
                        if (res.data.data !== '')
                            window.sessionStorage.setItem(
                                'errorFileName',
                                res.data.data
                            );
                    } else {
                        message.success(res.data.message);
                        isImport.value = true;
                    }
                })
                .catch((err) => {
                    console.log(err);
                    console.log('asdgaetwertewt');
                });
        };
        // 上传功能出错后下载模态窗口
        const handleDownload = () => {
            loading.value = true;
            // 调下载错误文件接口
            const path = window.sessionStorage.getItem('errorFileName')
                ? window.sessionStorage.getItem('errorFileName')
                : null;
            if (!path) return;
            const downloadParams = {
                // payoutRoundId: roundVal.value,
                path: window.sessionStorage.getItem('errorFileName'),
            };
            const params = {
                url: `/claimapi/common/download/errorFile`,
                method: 'get',
                params: downloadParams,
                // fileName: `${+new Date()}.xlsx`,
            };
            downloadFileUrl(
                params,
                'application/vnd-excel; char-set=UTF-8'
            ).then((res) => {
                loading.value = false;
                downloadModalVisible.value = false;
                emptyVisible.value = false;
            });
        };
        const handleCancel = () => {
            downloadModalVisible.value = false;
        };
        const showUploadHandle = () => {
            isBeforeUpload.value = true;
            isShowUploadBtn.value = true;
            curPercent.value = 0;
            completeTips.value = false;
            window.sessionStorage.removeItem('errorFileName');
            isImport.value = false;
        };

        // 下载模板接口调用
        const downloadTemHandle = () => {
            if (queryCheck()) return;
            if (route.name === 'ta-management') {
                const params = {
                    url: '/claimapi/claimChecking/taManagement/download',
                    method: 'post',
                    params: {
                        bu: bu.value,
                        year: year.value,
                        quarter: quarter.value,
                    },
                };
                downLoadTemplate(
                    params,
                    'application/vnd-excel; char-set=UTF-8'
                );
            }
            if (route.name === 'ag-management') {
                const params = {
                    url: '/claimapi/claimChecking/agManagement/template',
                    method: 'get',
                    params: {
                        bu: bu.value,
                        year: year.value,
                    },
                };
                downLoadTemplate(
                    params,
                    'application/vnd-excel; char-set=UTF-8'
                );
            }
        };

        return {
            year,
            yearList,
            bu,
            quarter,
            quarterList,
            isEmptyYear,
            isEmptyBu,
            isEmptyQuarter,
            //
            loading,
            // isShowErrorTips,
            downloadModalVisible,
            downloadTipsText,
            handleDownload,
            handleCancel,
            roundVal,
            title,
            modal,
            close,
            uploadFile,
            beforeUpload,
            downloadTemHandle,
            uploadComplete,
            isBeforeUpload,
            isShowUploadBtn,
            uploadRequest,
            curPercent,
            curUploadFile,
            uploadFileSize,
            isImport,
            showUploadHandle,
            completeTips,
            isClickAbled,
            emptyVisible,
            emptyTipsText,
        };
    },
});
