// AG查询接口
export interface AGManagementQueryParams {
    name?: string | null;
    year?: string | null;
    status?: string | null;
    businessUnit?: string | null;
    current?: number;
    size?: number;
}

// AGManagement 表格数据接口
export interface AGManagementTableData {
    id?: number;
    name: string;
    year: string;
    dealerCount: number;
    cbu?: number;
    pbp?: number;
    total: number;
    status: string;
    uploadBy: string;
    uploadAt: string;
}

export const agColumns1 = [
    { title: 'Name', dataIndex: 'name' },
    {
        title: 'Year',
        dataIndex: 'year',
        width: 80,
    },
    {
        title: 'Dealer Count',
        dataIndex: 'dealerCount',
        width: 130,
    },
    { title: 'CBU', dataIndex: 'cbu', width: 130 },
    { title: 'PBP', dataIndex: 'pbp', width: 130 },
    { title: 'Total', dataIndex: 'total', width: 130 },
    {
        title: 'Status',
        dataIndex: 'status',
        width: 100,
        slots: { customRender: 'status' },
    },
    {
        title: 'Upload User',
        dataIndex: 'uploadUser',
        width: 180,
    },
    {
        title: 'Upload Time',
        dataIndex: 'uploadTime',
        width: 180,
    },
];
export const agColumns2 = [
    { title: 'Name', dataIndex: 'name' },
    {
        title: 'Year',
        dataIndex: 'year',
        width: 80,
    },
    {
        title: 'Dealer Count',
        dataIndex: 'dealerCount',
        width: 130,
    },
    { title: 'Total', dataIndex: 'total', width: 130 },
    {
        title: 'Status',
        dataIndex: 'status',
        width: 100,
        slots: { customRender: 'status' },
    },
    {
        title: 'Upload User',
        dataIndex: 'uploadUser',
        width: 180,
    },
    {
        title: 'Upload Time',
        dataIndex: 'uploadTime',
        width: 180,
    },
];

// export const agColumns2 = [
//     { title: 'Name', dataIndex: 'name', width: 120 },
//     {
//         title: 'Year',
//         dataIndex: 'year',
//         width: 80,
//
//     },
//     {
//         title: 'Dealer Count',
//         dataIndex: 'dealerCount',
//         width: 130,
//
//     },
//     { title: 'Total', dataIndex: 'total', width: 100 },
//     {
//         title: 'Status',
//         dataIndex: 'status',
//         width: 100,
//         slots: { customRender: 'status' },
//
//     },
//     {
//         title: 'Upload User',
//         dataIndex: 'uploadUser',
//         width: 100,
//
//     },
//     {
//         title: 'Upload Time',
//         dataIndex: 'uploadTime',
//         width: 100,
//
//     },
// ];

// TA 查询接口
export interface TAManagementQueryParams {
    name?: string | null;
    year?: string | null;
    type?: string | null;
    quarter?: string | null;
    businessUnit?: string;
    current?: number;
    size?: number;
}

// TAManagement 表格数据接口
export interface TAManagementTableData {
    id?: number;
    name: string;
    type: string;
    year: string;
    quarter: string;
    dealercount: number;
    CBU?: number;
    PbP?: number;
    total: number;
    status: string;
    uploaduser: string;
    uploadtime: string;
    current: number;
    size: number;
}

export const taColumns1 = [
    { title: 'Name', dataIndex: 'name' },
    {
        title: 'Type',
        dataIndex: 'type',
        width: 80,
    },
    {
        title: 'Year',
        dataIndex: 'year',
        width: 80,
    },
    {
        title: 'Quarter',
        dataIndex: 'quarter',
        width: 100,
    },
    {
        title: 'Dealer Count',
        dataIndex: 'dealercount',
        width: 130,
    },
    { title: 'CBU', dataIndex: 'CBU', width: 130 },
    { title: 'PBP', dataIndex: 'PbP', width: 130 },
    { title: 'Total', dataIndex: 'total', width: 130 },
    {
        title: 'Status',
        dataIndex: 'status',
        width: 120,
        slots: { customRender: 'status' },
    },
    {
        title: 'Upload User',
        dataIndex: 'uploaduser',
        width: 180,
    },
    {
        title: 'Upload Time',
        dataIndex: 'uploadtime',
        width: 180,
    },
];

export const taColumns2 = [
    { title: 'Name', dataIndex: 'name' },
    {
        title: 'Type',
        dataIndex: 'type',
        width: 80,
        align: 'left',
    },
    {
        title: 'Year',
        dataIndex: 'year',
        width: 80,
    },
    {
        title: 'Quarter',
        dataIndex: 'quarter',
        width: 100,
    },
    {
        title: 'Dealer Count',
        dataIndex: 'dealercount',
        width: 130,
    },
    { title: 'Total', dataIndex: 'total', width: 130 },
    {
        title: 'Status',
        dataIndex: 'status',
        width: 120,
        slots: { customRender: 'status' },
    },
    {
        title: 'Upload User',
        dataIndex: 'uploaduser',
        width: 180,
    },
    {
        title: 'Upload Time',
        dataIndex: 'uploadtime',
        width: 180,
    },
];

// export const taColumns2 = [
//     { title: 'Name', dataIndex: 'name', width: 120 },
//     {
//         title: 'Type',
//         dataIndex: 'type',
//         width: 80,
//
//     },
//     {
//         title: 'Year',
//         dataIndex: 'year',
//         width: 80,
//
//     },
//     {
//         title: 'Quarter',
//         dataIndex: 'quarter',
//         width: 80,
//
//     },
//     {
//         title: 'Dealer Count',
//         dataIndex: 'dealercount',
//         width: 130,
//
//     },

//     { title: 'Total', dataIndex: 'total', width: 100 },
//     {
//         title: 'Status',
//         dataIndex: 'status',
//         width: 100,
//         slots: { customRender: 'status' },
//
//     },
//     {
//         title: 'Upload User',
//         dataIndex: 'uploaduser',
//         width: 100,
//
//     },
//     {
//         title: 'Upload Time',
//         dataIndex: 'uploadtime',
//         width: 100,
//
//     },
// ];
